.wrapper {
  display: flex;

  :global(.image-gallery-image) {
    object-fit: cover !important;
  }
}

.wrapper_base {
  padding: 10px;
}

.wrapper_column {
  flex-direction: column;
}

.wrapper50 {
  .block {
    width: 50%;
  }
}

.block {
  flex-shrink: 0;
  width: 33%;
  padding: 20px;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
}

.block_image {
  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.title {
  padding: 20px;
  padding-bottom: 0;

  h1 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper50 {
    flex-direction: column;

    .block {
      width: 100%;
    }
  }
}

@media screen and (max-width: 920px) {
  .wrapper_base {
    flex-direction: column;
  }

  .block_image {
    width: 100%;
    order: 2;
  }
}

@media screen and (max-width: 500px) {
  .block_image {
    order: 1;
  }
}