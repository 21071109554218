.wrapper {
  padding: 10px 0;
}

.title {
  padding: 10px 20px;
}

.reviewContainer {
  display: flex;
  gap: 20px 10px;
  padding: 0 20px;
  flex-wrap: wrap;
}

.review {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 30%;
  gap: 10px;
}

.review__ava {
  font-size: 20px;
  font-weight: bold;
}

.review__ava img, .review__ava object {
  width: 70px;
  height: 70px;
}

.review__name {
  font-size: 20px;
  font-weight: bold;
}

.review__text {
  font-size: 18px;
  line-height: 26px;
}

@media screen and (max-width: 980px) {
  .review {
    width: calc(50% - 40px);
  }
}

@media screen and (max-width: 650px) {
  .review {
    width: 100%;
  }
}