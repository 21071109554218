body {
  font-family: 'Roboto', sans-serif;
  color: #202020;
}

.wrapper {
  max-width: 1300px;
  margin: auto;
  padding-bottom: 100px;
}

h1 {
  font-size: 40px;
  font-weight: bold;
}

h2 {
  font-size: 22px;
  font-weight: bold;
  line-height: 33px;
}

b {
  font-weight: bold;
}