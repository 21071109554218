.wrapper {
  background: #f2f2f2;
  display: flex;
  min-height: 500px;
}

.infoBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  flex-direction: column;
}

.items {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 15px;
  gap: 10px;
  line-height: 21px;
  padding-left: 23px;
  font-size: 16px;
}

.infoBlockPhone,
.infoBlockYoutube,
.infoBlockWA {
  text-align: center;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 10px;
  position: relative;
  color: #178e0a;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: " ";
    background: url('../../assets/whatsapp.png') center center no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.infoBlockPhone {
  color: #1d7ecb;

  &:before {
    background: url('../../assets/telephone.png')  center center no-repeat;
    background-size: contain;
  }
}

.infoBlockYoutube {
  color: #db4646;

  &:before {
    background: url('../../assets/youtube.png')  center center no-repeat;
    background-size: contain;
  }
}

.infoContacts {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.infoContactsImg {
  display: none;
  flex-direction: column;
  position: absolute;
  right: 10px;
  top: 15px;

  .infoBlockYoutube,
  .infoBlockPhone {
    margin-top: 10px;
  }

  .infoBlockWA,
  .infoBlockYoutube,
  .infoBlockPhone {
    background: #fff;
    font-size: 15px;
    border-radius: 20px;
    padding: 10px;
  }
}

.wrapperRight {
  margin-left: auto;
  max-width: 595px;
  overflow: hidden;

  img {
    height: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .infoContacts {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .infoContacts {
    display: none;
  }
  .infoContactsImg {
    display: flex;
  }

  .wrapper {
    flex-direction: column-reverse;
    height: auto;
  }

  .infoBlock {
    text-align: left;
  }

  .wrapperRight {
    max-width: 100%;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      min-height: 315px;
    }
  }
}

.sale {
  //color: #ffab00;
}