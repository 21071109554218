.wrapper {
  padding: 20px;
}

.itemWrapper {
  display: flex;
  flex-flow: wrap;
}

.item {
  color: inherit;
  display: block;
  text-decoration: none;
  width: 300px;
  min-width: 300px;
  height: 170px;
  padding: 10px;
}

.itemCover {
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.itemTitle {
  background: rgb(0 0 0 / 51%);
  color: #fff;
  padding: 10px;
  font-size: 14px;
  position: absolute;
  bottom: 0;
}