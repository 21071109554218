.wrapper {
  box-shadow: 0 4px 12px rgb(0 16 61 / 16%);
  transition: box-shadow 0.15s ease-in-out;
  background: #fff;
  margin: 15px;
  border-radius: 10px;
  overflow: hidden;
}

.content {
  padding: 0 25px;
}

.title {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  padding: 10px 30px;
  background: #0039a1;
  color: #fff;
}

.subTitle {
  text-align: left;
  color: #fff;
  font-size: 17px;
  margin-top: 5px;
}

.price {
  padding: 20px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.button {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 20px 0 15px;
  position: relative;
  color: #178e0a;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: " ";
    background: url('../../assets/whatsapp.png') center center no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.desc {
  display: flex;
  flex-direction: column;
  padding: 0 35px;
}

.descItem {
  position: relative;
  padding-left: 40px;
  line-height: 30px;
  margin-top: 10px;

  &:before {
    content: ' ';
    background: url('./icon.png');
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    background-size: contain;
  }
}