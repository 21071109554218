.wrapper {
    padding: 10px 20px;
}

.wrapperItems {
    padding: 0 10px;
}

.item {
    padding: 8px 0;
    display: flex;
    align-items: center;
    line-height: 23px;
}

.itemPic {
    margin: 10px;
    width: 100px;
    min-width: 100px;
    height: 50px;
    background: #fff no-repeat center center;
    background-size: cover;
}

.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
}